var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "tabs-component",
            { staticClass: "pl-2 pt-2", attrs: { pills: "" } },
            [
              _c("b-tab", {
                attrs: { active: "" },
                on: {
                  click: function ($event) {
                    _vm.tabVal = 1
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function () {
                      return [_vm._v(" Details ")]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("b-tab", {
                on: {
                  click: function ($event) {
                    _vm.tabVal = 2
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function () {
                      return [_vm._v(" Profiles ")]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("b-tab", {
                on: {
                  click: function ($event) {
                    _vm.tabVal = 3
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function () {
                      return [_vm._v(" Specialties ")]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("b-tab", {
                on: {
                  click: function ($event) {
                    _vm.tabVal = 4
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function () {
                      return [_vm._v(" Activity & Notes ")]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
          _c("ProviderInfoCC", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tabVal == 1,
                expression: "tabVal == 1",
              },
            ],
            ref: "provider-details",
            attrs: { mode: _vm.mode },
            on: { save: _vm.save, "delete-provider": _vm.deleteProvider },
            model: {
              value: _vm.provider,
              callback: function ($$v) {
                _vm.provider = $$v
              },
              expression: "provider",
            },
          }),
          _c("profiles-component", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tabVal == 2,
                expression: "tabVal == 2",
              },
            ],
          }),
          _c("SpecialtiesCC", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tabVal == 3,
                expression: "tabVal == 3",
              },
            ],
            on: { save: _vm.save },
            model: {
              value: _vm.provider,
              callback: function ($$v) {
                _vm.provider = $$v
              },
              expression: "provider",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }